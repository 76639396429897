@font-face {
  font-family: 'ProximaNova';
  src: url(../assets/fonts/ProximaNova-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'ProximaNova';
  src: url(../assets/fonts/ProximaNova-Semibold.woff) format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'ProximaNova';
  src: url(../assets/fonts/ProximaNova-Bold.woff) format('woff');
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'ProximaNova', sans-serif;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.35;
  color: #343434;
}

button, input {
  background: transparent;
  border: none;
  outline: none;
}

button, a {
  cursor: pointer;
}
